import React from "react";
import "./section_5.css";
import tick from "../assets/tick-symbol.png";

const Section5 = () => {
  return (
    <section id="paymentId" className="sec-5 show-animate">
      <div className="payment-info-div">
        <h1 className="payment-title">Book Your Training Now !</h1>

        <div className="payment-container">
          <div className="payment-method-1 animate">
            <div className="basic-plan-column">
              <div className="pro-plan-mode-1">
                <h1 className="plans-h1 basic-h1">
                  Practical Engineering Design Consultancy Training
                </h1>
                <b>(Pre-Recorded Video Mode)</b>
                <b className="plan-price-main">
                  <span className="plan-price-offer">Winter Season Special Offer! Rs.2,999/-</span>{" "}
                  <span className="plan-price-old">Rs.11,666</span>
                </b>
              </div>

              <p className="plans-p3">
                for 1-year view access only to videos and downloadable access to 1000+ files.
              </p>
              <p className="plans-p2">
                Get started with our Basic plan and kickstart your learning
                journey
              </p>

              <div className="payment-center-line"></div>

              <ul className="basic-plan-features">
                <li>
                  <img src={tick} alt="" />
                  Duration - 2 months.
                </li>
                <li>
                  <img src={tick} alt="" />
                  700 hrs pre-recorded videos.
                </li>
                <li>
                  <img src={tick} alt="" />
                  Doubts & clarification in online/phone
                </li>
                <li>
                  <img src={tick} alt="" />
                  Pdf Code books, Notes, 250+E-Books collection, IS, American &
                  300+ BS code design xl sheets supply.
                </li>
                <li>
                  <img src={tick} alt="" />
                  Bonus consultancy set up Training videos.
                </li>
                <li>
                  <img src={tick} alt="" />
                  Bonus Digital marketing campaign 1 no set up.
                </li>
                <li>
                  <img src={tick} alt="" />
                  Certification.
                </li>
              </ul>

              <a
                href="https://rzp.io/l/phBlT9OqT"
                target="_blank"
                rel="noreferrer"
                className="plans-link"
              >
                <button className="plans-button">BOOK NOW</button>
              </a>
            </div>
          </div>

          <div className="payment-center-line-forcolumn">
            <p className="or">or</p>
          </div>
          <div className="payment-method-2 animate">
            <p className="payment-info2">
              You can also pay directly to UPI Gpay/PhonePe/Paytm no.
              9629121300. <b className="font-bold-payment">IMPORTANT:</b> After
              payment send receipt & gmail id to WhatsApp no.{" "}
              <b className="font-bold-payment">+91-9629121300</b>. We will
              dispatch the course contents to your gmail id and google drive.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section5;
