import React from "react";
import "./msgpopup.css";
import close from "../assets/closeImg.png";

const Msgpopup = () => {
  const closePopup2 = () => {
    document.querySelector(".msg-popupdiv2").style.display = "none";
  };

  return (
    <div className="msg-popupdiv2">
      <div className="msg-contactPage">
        <button onClick={closePopup2} className="close-btn">
          <img src={close} alt="close-button" />
        </button>
        <p className="p1-text">
          If you have filled the form, please wait while the{" "}
          <span>FREE Course & FREE E-Book</span> download links are getting
          loaded !
        </p>
        <p>
          Additionally, you can also access the contents below the home page
          section of the website once loaded.
        </p>
      </div>
    </div>
  );
};

export default Msgpopup;
